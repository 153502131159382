import React from 'react'
import { object } from 'prop-types'

import { LazyImage } from 'components/LazyImage'

import { Container } from './fullWidthImage.style'

const propTypes = {
  imageDesktop1: object,
  imageDesktop2: object,
  imageDesktop3: object,
  imageMobile1: object,
}

const FullWidthImage = ({
  imageDesktop1,
  imageDesktop2,
  imageDesktop3,
  imageMobile1,
  ...others
}) => {
  const allImages = [imageDesktop1, imageDesktop2, imageDesktop3, imageMobile1]
  const sources = [
    {
      ...imageDesktop1?.fluid,
      media: '(min-width: 1441px)',
      sizes: '(max-width: 1920px)',
    },
    {
      ...imageDesktop2?.fluid,
      media: '(min-width: 1025px)',
      sizes: '(max-width: 1440px)',
    },
    {
      ...imageDesktop3?.fluid,
      media: '(min-width: 729px)',
      sizes: '(max-width: 1024px)',
    },
    {
      ...imageMobile1?.fluid,
      sizes: '(max-width: 728px)',
    },
  ]

  const alt = allImages.find(image => image?.description)?.description || ''
  const haveSomeImage = allImages.some(image => image)

  return (
    <Container data-testid="container" {...others}>
      {haveSomeImage && <LazyImage artDirection={sources} alt={alt} />}
    </Container>
  )
}

FullWidthImage.propTypes = propTypes

export { FullWidthImage }
export default FullWidthImage
